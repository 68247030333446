<template>
  <div class="row align-items-center justify-content-center text-center">
    <ResetPasswordForm v-if="hasResetPassTokenRef" />
    <RequestResetPasswordForm v-else />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import RequestResetPasswordForm from '../../components/auth/RequestResetPasswordForm';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';

export default {
  components: { RequestResetPasswordForm, ResetPasswordForm },
  setup(props, context) {
    const hasResetPassTokenRef = ref(context.root.$route.params.token || false);
    return {
      hasResetPassTokenRef,
    };
  },
};
</script>

<style lang="scss" scoped></style>
