<template>
  <div class="col-12">
    <template v-if="!formStateRef.resetSent">
      <h1 class="font-32 mb-4">
        Forgot your password?
      </h1>
      <p class="font-17 mb-4">
        Enter your email address below and we'll get you back on track.
      </p>
    </template>
    <div
      v-else
      class="mt-5 mb-5 ml-3 text-left email-sent-text"
    >
      <h3>
        We've sent you an email with instructions to reset your password.
      </h3>
    </div>
    <ElForm
      v-if="!formStateRef.resetSent"
      ref="form"
      :model="payload"
      :rules="rulesRef"
      class="mt-1"
      @submit.native.prevent="requestResetPassword"
    >
      <SdFloatLabel>
        <ElFormItem prop="email">
          <ElInput
            v-model="payload.email"
            placeholder="Email"
          />
        </ElFormItem>
      </SdFloatLabel>
      <ElButton
        :loading="formStateRef.loading"
        type="primary"
        class="w-100 mt-4"
        @click="requestResetPassword"
      >
        Reset password
      </ElButton>
      <div class="text-center mt-3">
        <router-link
          class="font-weight-bold"
          :to="{ name: 'auth.sign-in' }"
        >
          Back to sign in
        </router-link>
      </div>
    </ElForm>
    <div
      v-if="formStateRef.resetSent"
      class="col-12 text-center"
    >
      <ElButton
        type="primary-light"
        class="w-100"
        @click="backToSignIn"
      >
        Back to sign in
      </ElButton>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { redirectToSignIn } from '@/router';

import { showErrorNotify } from '@/utils/NotifyUtil';
import SdFloatLabel from '../common/form/SdFloatLabel';

export default {
  components: { SdFloatLabel },
  setup(props, context) {
    const payload = reactive({
      email: '',
    });
    const rulesRef = ref(formRules);
    const formStateRef = ref(defaultFormState);
    return {
      payload,
      formStateRef,
      rulesRef,
      requestResetPassword,
      backToSignIn,
    };

    async function requestResetPassword() {
      formStateRef.value.loading = true;
      try {
        payload.email = payload.email.trim();
        await context.refs.form.validate();
        await context.root.$store.dispatch('Auth/requestResetPassword', payload);
        formStateRef.value.resetSent = true;
        formStateRef.value.loading = false;
      } catch (error) {
        formStateRef.value.loading = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    function backToSignIn() {
      redirectToSignIn(context);
      formStateRef.value.resetSent = false;
    }
  },
};
const formRules = {
  email: [
    {
      required: true,
      type: 'email',
      message: 'Email is required',
    },
  ],
};
const defaultFormState = {
  loading: false,
  resetSent: false,
};
</script>

<style lang="scss">
</style>
