<template>
  <div class="col-12">
    <template>
      <h1 class="font-32 mt-5 mb-4">
        Reset Account Password
      </h1>
    </template>
    <ElForm
      ref="form"
      :model="payload"
      :rules="rules"
      @submit.native.prevent="resetPassword"
    >
      <SdFloatLabel>
        <ElFormItem
          prop="password"
          class="mb-3"
        >
          <ElInput
            v-model="payload.password"
            placeholder="New password"
            type="password"
            autocomplete="off"
          />
        </ElFormItem>
      </SdFloatLabel>
      <SdFloatLabel>
        <ElFormItem
          prop="password_confirmation"
        >
          <ElInput
            v-model="payload.password_confirmation"
            placeholder="Confirm new password"
            type="password"
            autocomplete="off"
          />
        </ElFormItem>
      </SdFloatLabel>
      <ElButton
        :loading="formStateRef.loading"
        type="primary"
        class="w-100 mt-4"
        @click="resetPassword"
      >
        Change password
      </ElButton>
    </ElForm>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';

import SdFloatLabel from '../common/form/SdFloatLabel';
import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';
import { redirectToSignIn } from '../../router';

export default {
  components: { SdFloatLabel },
  setup(props, context) {
    const payload = reactive({
      password: null,
      password_confirmation: null,
      token: context.root.$route.params.token,
    });
    const formStateRef = ref(defaultFormState);

    const rules = {
      password: [
        {
          validator: validatePassword,
          trigger: 'blur',
        },
      ],
      password_confirmation: [
        {
          validator: validateConfirmPassword,
          trigger: 'blur',
        },
      ],
    };

    return {
      payload,
      formStateRef,
      rules,
      resetPassword,
      validatePassword,
      validateConfirmPassword,
    };

    async function resetPassword() {
      formStateRef.value.loading = true;
      try {
        await context.refs.form.validate();
        await context.root.$store.dispatch('Auth/resetPassword', payload);
        formStateRef.value.loading = false;
        showSuccessNotify(context, 'Your account password has been changed successfully');
        redirectToSignIn(context);
      } catch (error) {
        formStateRef.value.loading = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    function validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error('Password is required'));
      } else if (value.length < 6) {
        callback(new Error('Password must be at least 6 characters'));
      } else {
        if (payload.password_confirmation !== '') {
          context.refs.form.validateField('password_confirmation');
        }
        callback();
      }
    }

    function validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Confirm Password field is required'));
      } else if (value !== payload.password) {
        callback(new Error('Two Passwords don\'t match!'));
      } else {
        callback();
      }
    }
  },
};

const defaultFormState = {
  loading: false,
};
</script>

<style lang="scss">
</style>
